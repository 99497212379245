<template>
    <div>
        <b-row>
            <b-col class="d-flex justify-content-start mb-1">
                <div>
                    <b-input-group size="sm">
                        <b-input-group-prepend>
                            <b-input-group-text>Pro Seite</b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-select
                            id="per-page-select"
                            v-model="perPage"
                            :options="pageOptions"
                            size="sm"
                            style="min-width: 100px"
                        ></b-form-select>
                    </b-input-group>
                </div>
                <b-button :disabled="selectedItems.length <= 0" variant="success" class="btn-xs mb-0 ml-1" :title="$t('buttons.start')" @click="sendMultiAction('start')">
                    <font-awesome-icon icon="play" fixed-width/>
                </b-button>
                <b-button :disabled="selectedItems.length <= 0" variant="info" class="btn-xs mb-0 ml-1" :title="$t('buttons.reboot')" @click="sendMultiAction('reboot')">
                    <font-awesome-icon icon="undo" fixed-width/>
                </b-button>
                <b-button :disabled="selectedItems.length <= 0" variant="purple" class="btn-xs mb-0 ml-1" :title="$t('buttons.shutdown')" @click="sendMultiAction('shutdown')">
                    <font-awesome-icon icon="power-off" fixed-width/>
                </b-button>
                <b-button :disabled="selectedItems.length <= 0" variant="warning" class="btn-xs mb-0 ml-1" :title="$t('buttons.reset')" @click="sendMultiAction('reset')">
                    <font-awesome-icon icon="redo" fixed-width/>
                </b-button>
                <b-button :disabled="selectedItems.length <= 0" variant="danger" class="btn-xs mb-0 ml-1" :title="$t('buttons.hardStop')" @click="sendMultiAction('stop')">
                    <font-awesome-icon icon="stop" fixed-width/>
                </b-button>
                <b-button :disabled="selectedItems.length <= 0" variant="danger" class="btn-xs mb-0 ml-1" :title="$t('buttons.delete')" @click="sendMultiAction('delete')">
                    <font-awesome-icon icon="trash" fixed-width/>
                </b-button>
            </b-col>
            <b-col sm="4" md="5" xl="4" class="mb-1">
                <b-input-group size="sm">
                    <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        :placeholder="$t('generally.tables.tipToSearch')"
                    ></b-form-input>
                    <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">{{ $t('generally.tables.clear') }}</b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-table
                    :items="items"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    @filtered="onFiltered"
                    stacked="md"
                    show-empty
                    striped
                    small
                    selectable
                    select-mode="multi"
                    @row-selected="onSelected"
                    ref="vmTable"
                    class="bg-white"
                    :empty-text="$t('generally.tables.emptyText')"
                    :empty-filtered-text="$t('generally.tables.emptyFilteredText')"
                    :busy="tableLoading"
                    sort-by="name"
                >
                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                    </template>
                    
                    <template #head(selected)="{}">
                        <template v-if="selectAll">
                            <span aria-hidden="true" href="#" @click.prevent="checkAll()"><font-awesome-icon :icon="['far', 'check-square']"/></span>
                        </template>
                        <template v-else>
                            <span aria-hidden="true" href="#" @click.prevent="checkAll()"><font-awesome-icon :icon="['far', 'square']"/></span>
                        </template>
                    </template>

                    <template #cell(selected)="{ rowSelected }">
                        <template v-if="rowSelected">
                            <span aria-hidden="true"><font-awesome-icon :icon="['far', 'check-square']"/></span>
                            <span class="sr-only">Selected</span>
                        </template>
                        <template v-else>
                            <span aria-hidden="true"><font-awesome-icon :icon="['far', 'square']"/></span>
                            <span class="sr-only">Not selected</span>
                        </template>
                    </template>
                    <template #cell(parent_folder)="row">
                        {{ row.item.parent_folder ? row.item.parent_folder.name : '' }}
                    </template>
                     <template #cell(ip_preferred)="row">
                        {{ row.item.network.ipPreferred ? row.item.network.ipPreferred : '' }}
                    </template>
                    <template #cell(cpu_count)="row">
                        {{ row.item.cpu_count }}
                    </template>
                    <template #cell(memory_size_MiB)="row">
                        {{ row.item.memory_size_MiB > 1024 ? Math.round(row.item.memory_size_MiB / 1024) : row.item.memory_size_MiB }} {{ row.item.memory_size_MiB > 1024 ? 'GiB' : 'MiB' }}
                    </template>
                    <template #cell(power_state)="row">
                        <font-awesome-icon :class="'text-'+(row.item.power_state === 'POWERED_OFF' ? 'danger' : 'success')" :icon="row.item.power_state === 'POWERED_OFF' ? 'stop' : 'play'"/>
                    </template>
                    <template #cell(actions)="row">
                        <b-button variant="primary" class="btn-xs mb-0 ml-1" :title="$t('buttons.show')" @click="loadSingle(row.item.vm)">
                            <font-awesome-icon icon="eye"/>
                        </b-button>
                        <b-button v-show="row.item.power_state === 'POWERED_OFF'" variant="success" class="btn-xs mb-0 ml-1" :title="$t('buttons.start')" @click="openActionModal(row.item, 'start')">
                            <font-awesome-icon icon="play"/>
                        </b-button>
                        <b-button v-show="row.item.power_state === 'POWERED_ON'" variant="info" class="btn-xs mb-0 ml-1" :title="$t('buttons.reboot')" @click="openActionModal(row.item, 'reboot')">
                            <font-awesome-icon icon="undo"/>
                        </b-button>
                        <b-button v-show="row.item.power_state === 'POWERED_ON'" variant="purple" class="btn-xs mb-0 ml-1" :title="$t('buttons.shutdown')" @click="openActionModal(row.item, 'shutdown')">
                            <font-awesome-icon icon="power-off"/>
                        </b-button>
                        <b-button v-show="row.item.power_state === 'POWERED_ON'" variant="warning" class="btn-xs mb-0 ml-1" :title="$t('buttons.reset')" @click="openActionModal(row.item, 'reset')">
                            <font-awesome-icon icon="redo"/>
                        </b-button>
                        <b-button v-show="row.item.power_state === 'POWERED_ON'" variant="danger" class="btn-xs mb-0 ml-1" :title="$t('buttons.hardStop')" @click="openActionModal(row.item, 'stop')">
                            <font-awesome-icon icon="stop"/>
                        </b-button>
                        <b-button v-show="row.item.power_state === 'POWERED_OFF'" variant="danger" class="btn-xs mb-0 ml-1" :title="$t('buttons.delete')" @click="openActionModal(row.item, 'delete')">
                            <font-awesome-icon icon="trash"/>
                        </b-button>
                    </template>
                    <template #row-details="row">
                        <b-card>
                            <ul>
                                <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                            </ul>
                        </b-card>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <b-row class="mb-3">
            <b-col>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="right"
                    size="sm"
                    class="my-0"
                ></b-pagination>
            </b-col>
        </b-row>
        <b-modal id="modal-1" title="Inhalt" size="xl">
            <vue-json-pretty :path="'res'" :data="json"></vue-json-pretty>
        </b-modal>
        <b-modal
            id="action-vm-modal"
            :title="$t('pages.vdi.vm.modal.action.title')"
            :cancel-title="$t('buttons.no')"
            :ok-title="$t('buttons.yes')"
            ok-variant="danger"
            @ok="onSubmitAction"
        >
            <span v-html="$t('pages.vdi.vm.modal.action.message', { action: $t('buttons.' + selectedItem.action), vm: selectedItem.item.name})"></span>
        </b-modal>
        <b-modal
            id="multi-action-vm-modal"
            :title="$t('pages.vdi.vm.modal.action.title')"
            :cancel-title="$t('buttons.no')"
            :ok-title="$t('buttons.yes')"
            ok-variant="danger"
            @ok="onSubmitMultiAction"
        >
            <span v-html="$t('pages.vdi.vm.modal.action.message', { action: $t('buttons.' + selectedAction), vm: selectedVMs})"></span>
        </b-modal>
    </div>
</template>

<script>
import api from "../../../services/api";
import vmService from "../../../services/vmService";
import VueJsonPretty from "vue-json-pretty";
import 'vue-json-pretty/lib/styles.css';
import {EventBus} from "../../../services/EventBus";
import mainServices from "../../../services/mainServices";

export default {
    name: "PoolList",
    data: () => ({
        fields: [
            {key: 'selected', label: ''},
            {key: 'name', label: 'Name', sortable: true, sortDirection: 'asc'},
            {key: 'parent_folder', label: 'Ordner', sortable: true, sortDirection: 'asc'},
            {key: 'ip_preferred', label: 'IP', sortable: true, sortDirection: 'asc'},
            {key: 'cpu_count', label: 'CPU', sortable: true, sortDirection: 'asc'},
            {key: 'memory_size_MiB', label: 'RAM', sortable: true, sortDirection: 'asc'},
            {key: 'power_state', label: 'Status', sortable: true},
            {key: 'actions', label: 'Aktionen'}
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 25,
        pageOptions: [5, 10, 15, 25, 50],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        items: [],
        selectAll: false,
        selectedItems: [],
        selectedAction: "",
        selectedVMs: "",
        json: '',
        selectedItem: {
            item: '',
            action: ''
        },
        updater: null,
        locale: 'de',
        tableLoading: true
    }),
    watch: {
        '$i18n.locale': function (newVal) {
            this.locale = newVal;
        }
    },
    mounted() {
        this.load();
        this.startUpdater();
        this.eventBus = EventBus.$on('sync', () => {
            this.load();
        });
        this.locale = mainServices.getLocale();
    },
    beforeDestroy() {
        this.stopUpdater();
        EventBus.$off('sync', this.eventBus);
    },
    components: {
        VueJsonPretty
    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        checkAll() {
            this.selectAll = !this.selectAll;
            if (this.selectAll) {
                this.$refs.vmTable.selectAllRows();
                this.selectedItems = this.items;
            } else {
                this.$refs.vmTable.clearSelected();
                this.selectedItems = [];
            }
        },
        onSelected(items) {
            this.selectAll = this.items.length === items.length;
            this.selectedItems = items;
        },
        load(full = false) {
            vmService.getAll(full).then(res => {
                if(JSON.stringify(this.items) != JSON.stringify(res.data)) {
                    this.items = res.data
                    this.totalRows = this.items.length;
                }
                this.tableLoading = false;
            })
            .catch(() => {
                this.tableLoading = false;
            });
        },
        loadSingle(id) {
            api().get("vCenter/vms/" + id).then(res => {
                this.json = res.data;
                this.$bvModal.show("modal-1");
            }).catch();
        },
        startUpdater(){
            this.updater = setInterval(this.load, 12000, true);
        },
        stopUpdater(){
            clearInterval(this.updater)
        },
        openActionModal(item, action){
            this.selectedItem.action = action;
            this.selectedItem.item = item;
            this.$bvModal.show('action-vm-modal')
        },
        sendMultiAction(action){
            this.selectedAction = action;
            this.selectedVMs = this.selectedItems.map(item => item.name).join(', ');
            this.$bvModal.show('multi-action-vm-modal')
        },
        onSubmitAction(){
            if(this.selectedItem.action === 'delete') {
                vmService.delete(this.selectedItem.item.vm, this.selectedItem.action).then(response => {
                    this.$toastr.s(this.$t('pages.vdi.vm.messages.success.sendAction', { action: this.$t('buttons.' + this.selectedItem.action), vm: this.selectedItem.item.name}));
                }).catch(error => {
                    this.errorHandler(error)
                });
            }
            else {
                vmService.sendPowerAction(this.selectedItem.item.vm, this.selectedItem.action).then(response => {
                    this.$toastr.s(this.$t('pages.vdi.vm.messages.success.sendAction', { action: this.$t('buttons.' + this.selectedItem.action), vm: this.selectedItem.item.name}));
                }).catch(error => {
                    this.errorHandler(error)
                });
            }
            this.$bvModal.hide('action-vm-modal')
        },
        onSubmitMultiAction(){
            for (const item of this.selectedItems) {
                if(this.selectedAction === 'delete') {
                    vmService.delete(item.vm, this.selectedAction).then(response => {
                        this.$toastr.s(this.$t('pages.vdi.vm.messages.success.sendAction', { action: this.$t('buttons.' + this.selectedAction), vm: item.name}));
                    }).catch(error => {
                        this.errorHandler(error)
                    });
                }
                else {
                    vmService.sendPowerAction(item.vm, this.selectedAction).then(response => {
                        this.$toastr.s(this.$t('pages.vdi.vm.messages.success.sendAction', { action: this.$t('buttons.' + this.selectedAction), vm: item.name}));
                    }).catch(error => {
                        this.errorHandler(error)
                    });
                }
            }
            this.$bvModal.hide('multi-action-vm-modal')
        }
    }

};
</script>

<style scoped>

</style>
